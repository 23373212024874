import React, { useEffect, useState } from "react";
import "./Styles/ImageColorAddition.css";
import { useDispatch, useSelector } from "react-redux";
import {
  ImageColorAdditionActions,
  getfilterData,
  // ImageColorAdditionAddActions,
} from "../../store/user/actions";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { Button, Select } from "antd";
import Dropzone from "react-dropzone";
import axios from "axios";
import { getToken } from "../../util/Common";
import { toast } from "react-toastify";
// import { color, variant } from "styled-system";
import { convertDropdown } from "../CreateFit/CreateFit";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../component/Loader";
import { GrSubtractCircle } from "react-icons/gr";
import { BASE_URL } from "../../BaseUri/BaseUri";

const thumbsContainer = {
  marginTop: 16,
};
const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  marginBottom: 8,
  width: 90,
  objectFit: "cover",
  height: 90,
  overflowClipMargin: "unset",
  overflow: "hidden",
  boxSizing: "border-box",
};
const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};
const img = {
  display: "block",
  width: "100%",
  borderRadius: "5px",
  height: "100%",
  overflowClipMargin: "unset",
  overflow: "hidden",
};

const ImageColorAddition = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

  // Get Image color Addition State
  const ImageColorAddition = useSelector((state) => state.ImageColorAddition);

  const filterData = useSelector((state) => state.filterData);

  useEffect(() => {
    dispatch(ImageColorAdditionActions(id));
    dispatch(getfilterData());
  }, [dispatch, id]);

  const [variations, setVariations] = useState([
    {
      color_id: "",
      photoshoot_type_id: "",
      photoshoot_model_id: "",
      party_id: "",
      images: [],
    },
  ]);

  const handleAddVariation = () => {
    setVariations([
      ...variations,
      {
        color_id: "",
        photoshoot_type_id: "",
        photoshoot_model_id: "",
        party_id: "",
        images: [],
      },
    ]);
  };

  const handleRemoveVariation = (index) => {
    setVariations(variations.filter((_, i) => i !== index));
  };

  const handleNameChange = (index, event) => {
    const newVariations = [...variations];
    newVariations[index].color_id = event;
    setVariations(newVariations);
  };

  const handlePhotoShootType = (index, event) => {
    const newVariations = [...variations];
    newVariations[index].photoshoot_type_id = event;
    setVariations(newVariations);
  };

  const handlePartyName = (index, event) => {
    const newVariations = [...variations];
    newVariations[index].party_id = event;
    setVariations(newVariations);
  };

  const handlePhotoshootModel = (index, event) => {
    const newVariations = [...variations];
    newVariations[index].photoshoot_model_id = event;
    setVariations(newVariations);
  };

  const handleFileChange = (index, acceptedFiles) => {
    const newVariations = [...variations];
    newVariations[index].images = acceptedFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    setVariations(newVariations);
  };

  const handleRemoveImage = (variationIndex, imageIndex) => {
    // console.log("variations:", variations);
    // console.log("variationIndex:", variationIndex);
    // console.log("images:", variations[variationIndex]?.images);

    if (variations[variationIndex]) {
      const newVariations = [...variations];
      newVariations[variationIndex].images = newVariations[
        variationIndex
      ].images.filter((_, i) => i !== imageIndex);
      setVariations(newVariations);
    } else {
      console.error("Variation index is out of bounds");
    }
  };

  const renderThumbs = (images = [], variationIndex) =>
    images.map((file, index) => (
      <div className="render-thumbs-row" style={thumb} key={file.name}>
        <div style={thumbInner}>
          <Button onClick={() => handleRemoveImage(variationIndex, index)}>
            <GrSubtractCircle />
          </Button>
          <img
            alt=""
            src={file.preview}
            style={img}
            onLoad={() => {
              URL.revokeObjectURL(file.preview);
            }}
          />
        </div>
      </div>
    ));

  // console.log("VARIATIONS: ", variations);

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    // Validation Field
    const isUnique = (arr, keys) => {
      const seen = new Set();
      for (const item of arr) {
        const key = keys.map((k) => item[k]).join("|"); // Combine values of specified keys
        if (seen.has(key)) return false;
        seen.add(key);
      }
      return true;
    };

    const validationKeys = [
      "color_id",
      "photoshoot_type_id",
      "photoshoot_model_id",
      "party_id",
    ];

    // Check for uniqueness
    if (!isUnique(variations, validationKeys)) {
      toast.error("All values except images must be unique.");
      return; // Stop submission
    }

    const hasEmptyFields = variations.some((variation) =>
      validationKeys.some((key) => !variation[key])
    );

    if (hasEmptyFields) {
      toast.error("Please fill in all required fields.");
      return; // Stop submission
    }
    // Validation Field

    // console.log("VARIATIONS: ", variations);

    const formData = new FormData();
    variations.forEach((i, index) => {
      formData.append(`data[${index}][color_id]`, i.color_id);
      formData.append(
        `data[${index}][photoshoot_type_id]`,
        i.photoshoot_type_id
      );
      formData.append(
        `data[${index}][photoshoot_model_id]`,
        i.photoshoot_model_id
      );
      formData.append(`data[${index}][party_id]`, i.party_id);
      i.images.forEach((j, ix) => {
        formData.append(`data[${index}][image][${ix}]`, j);
      });
    });

    setIsLoading(true);
    await axios({
      method: "POST",
      url: BASE_URL + `/sample_request/color_image_addition_add/${id}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      data: formData,
    })
      .then((response) => {
        if (response?.data?.status === 0) {
          toast.error(response?.data?.message);
          setIsLoading(false);
        } else if (response?.data?.status === 1) {
          toast.success("Data Saved Successfully");
          setIsLoading(false);
          navigate("/pending-task");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  // console.log("FILTER DATA: ", filterData);

  const [color_arr, setColor_arr] = useState([]);
  const [photoshootType_arr, setPhotoShootType_arr] = useState([]);
  const [party_arr, setParty_arr] = useState([]);
  const [photoshootModel_arr, setPhotoshootModel_arr] = useState([]);

  useEffect(() => {
    if (filterData && filterData?.isSuccess === true) {
      let color_data = convertDropdown(filterData && filterData?.data?.color);
      setColor_arr(color_data);

      let photoshootType_data = convertDropdown(
        filterData && filterData?.data?.photoshoot_type
      );
      setPhotoShootType_arr(photoshootType_data);

      let party_data = convertDropdown(filterData && filterData?.data?.party);
      setParty_arr(party_data);

      let photoshootModel_data = convertDropdown(
        filterData && filterData?.data?.photoshoot_model
      );
      setPhotoshootModel_arr(photoshootModel_data);
    }
  }, [filterData]);

  // console.log("I: ", ImageColorAddition);

  // console.log("VARIATIONS: ", variations);

  return (
    <div className="create-fit-wrapper">
      <div className="d-flex align-items-baseline justify-content-between pb-2">
        <div className="heading heading-row-master-fit">
          <img
            onClick={() => navigate(-1)}
            src="/Images/arrow-left.svg"
            alt="Back_Icon"
          />
          <h2>Image & Color Addition</h2>
        </div>
        <div className="download-but-excel"></div>
      </div>

      {ImageColorAddition?.isLoading ? (
        <div className="loader_icons">
          <Loader />
        </div>
      ) : (
        <>
          <div className="select-addition-wrapper">
            <div className="select-addition-grid-row">
              <div>
                <label className="labelx" htmlFor="">
                  Sample Collection Name
                </label>
                <br />
                <input
                  disabled={true}
                  type="text"
                  value={ImageColorAddition?.data?.collection}
                />
              </div>
              <div>
                <label className="labelx" htmlFor="">
                  Product Group
                </label>
                <br />
                <input
                  disabled={true}
                  type="text"
                  value={ImageColorAddition?.data?.product_group}
                />
              </div>
              <div>
                <label className="labelx" htmlFor="">
                  Child Category
                </label>
                <br />
                <input
                  disabled={true}
                  type="text"
                  value={ImageColorAddition?.data?.categorie}
                />
              </div>
            </div>
          </div>

          {variations.map((variation, index) => (
            <div key={index} className="color-variation-wrapper">
              <div
                className="color-variation-grid-row"
                style={{ gridTemplateColumns: "repeat(3,1fr)" }}
              >
                <div>
                  <div
                    style={{ justifyContent: "space-between", display: "flex" }}
                  >
                    <label className="labelx" htmlFor="">
                      Color Variation Name
                    </label>
                    <div className="d-flex flex-row align-items-center justify-content-center">
                      <Button className="mx-2" onClick={handleAddVariation}>
                        <PlusOutlined />
                      </Button>
                      {variations.length > 1 && (
                        <Button
                          className="mx-2"
                          onClick={() => handleRemoveVariation(index)}
                        >
                          <MinusOutlined />
                        </Button>
                      )}
                    </div>
                  </div>
                  <Select
                    className="mt-2"
                    options={color_arr}
                    onChange={(e) => handleNameChange(index, e)}
                  />
                </div>
                <div>
                  <label className="labelx" htmlFor="">
                    Photoshoot Type
                  </label>
                  <Select
                    className="mt-2"
                    options={photoshootType_arr}
                    onChange={(e) => handlePhotoShootType(index, e)}
                  />
                </div>
                <div>
                  <label className="labelx" htmlFor="">
                    Party
                  </label>
                  <Select
                    className="mt-2"
                    options={party_arr}
                    onChange={(e) => handlePartyName(index, e)}
                  />
                </div>
              </div>
              {/* new row */}
              <div
                className="color-variation-grid-row mt-2"
                style={{ gridTemplateColumns: "2fr 4fr" }}
              >
                <div>
                  <label className="labelx" htmlFor="">
                    Photoshoot Model
                  </label>
                  <Select
                    className="mt-2"
                    options={photoshootModel_arr}
                    onChange={(e) => handlePhotoshootModel(index, e)}
                  />
                </div>
                {/* <div> */}
                <div className="dropzone-row-wrapper">
                  <div>
                    <Dropzone
                      onDrop={(acceptedFiles) =>
                        handleFileChange(index, acceptedFiles)
                      }
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div
                          {...getRootProps({
                            className: "upload-file upload-images",
                          })}
                        >
                          <input {...getInputProps()} multiple />
                          <div>
                            <Button className="drop-zone-button">
                              <PlusOutlined />
                            </Button>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div className="aside-grid-row" style={thumbsContainer}>
                      {renderThumbs(variation.images || [], index)}
                    </div>
                  </div>
                </div>
                {/* </div> */}
              </div>
              {/* new row */}
            </div>
          ))}

          <div className="image-color-addition-cta">
            <Button onClick={handleSubmit}>
              {isLoading ? <Loader /> : "SUBMIT"}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default ImageColorAddition;
