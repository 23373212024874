import React, { useEffect, useState } from "react";
import Table from "../../../component/Table/Table";
import AudioPlayer from "../../ViewSubmissionDetails/component/AudioPlayer";
import { useDispatch, useSelector } from "react-redux";
import { getpreNodeSubmission } from "../../../store/user/actions";
import { Link, useParams } from "react-router-dom";
import UserNameImage from "../../../component/UserNameImage/UserNameImage";
import IMG_DEFAULT from "../../../no-image.jpg";
import DefaultModal from "../../../component/DefaultModal/DefaultModal";
import { CSV__URL, IMAGE_URL } from "../../../BaseUri/BaseUri";

const RejectionModal = ({ trainId, rejectionNodeManager }) => {
  const [tableData, setTableData] = useState([]);
  const [id, setId] = useState(0);
  const [designationId, setDesignationId] = useState(0);
  const [current, setCurrent] = useState(1);
  const preNodeSubmission = useSelector((state) => state.preNodeSubmission);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getpreNodeSubmission(trainId));
  }, []);

  const [image, setImage] = useState([]);
  const [ImageView, setImageView] = useState(false);

  useEffect(() => {
    const FakeTableData =
      preNodeSubmission &&
      preNodeSubmission.data.map((item, i) => {
        let sno = (current - 1) * 10 + 1;
        return [
          <>
            <label className="containerRadio">
              <input
                onClick={() => {
                  setDesignationId(item.manager_designation);
                  setId(item.sample_category_node_transaction_id);
                }}
                type="radio"
                name="radio"
              />
              <span className="checkmark_r"></span>
            </label>
          </>,
          <div className="currnt-nodes">
            <UserNameImage
              width="40px"
              height="40px"
              margin="0 auto"
              name={item.user_name}
              image={item.user_image}
            />
            <h6>{item.node_name}</h6>
            <h6>{item.user_name}</h6>
          </div>,
          <h6>{item.submit_datetime}</h6>,
          <div>
            <h6>{parseInt(item.delay) < 0 ? 0 : item.delay} Days</h6>
          </div>,
          <div className="view_im">
            {/* splice here */}
            <div className="d-grid-row">
              {item &&
                item.file?.length > 0 &&
                item.file.slice(0, 3).map((i, index) => {
                  // console.log("IXS: ", i);
                  return (
                    <div key={i + index}>
                      <img
                        onClick={() => {
                          setImageView(true);
                          setImage(item?.file);
                        }}
                        src={IMAGE_URL + i}
                        alt="im"
                      />
                    </div>
                  );
                })}
            </div>
            {/* splice here */}
          </div>,
          <div className="audo_io">
            <AudioPlayer audioSrc={item.audio} />
          </div>,
          <h6>{item.remark}</h6>,
          <div className="view_a">
            <Link
              style={{
                pointerEvents: item.document === "" ? "none" : "",
                background: item.document === "" ? "#c7c7c7" : "",
              }}
              to={CSV__URL + "download_submission_document/" + item.id}
              target="blank"
            >
              View
            </Link>
          </div>,
        ];
      });
    setTableData(FakeTableData);
  }, [preNodeSubmission]);

  const TemplateTableData = [
    { heading: "" },
    { heading: "Username" },
    { heading: "Submit Date" },
    { heading: "Delay Time" },
    { heading: "Image" },
    { heading: "Voice Note" },
    { heading: "Remark" },
    { heading: "Document" },
  ];
  return (
    <div>
      <div className="">
        <Table
          gridTemplateColumnsHeader="5% 15% 10% 10% 20% 10% 15% 15%"
          gridTemplateColumnsBody="5% 15% 10% 10% 20% 10% 15% 15%"
          columns={TemplateTableData}
          data={tableData}
        />
        {/* <Pagination
          current={current}
          onChange={onChange}
          total={totalData} /> */}
        <div className="mx-auto text-c text-center">
          <button
            className="dark-but"
            onClick={() => rejectionNodeManager(id, designationId)}
          >
            Next
          </button>
        </div>
      </div>
      {/* Modal */}
      <DefaultModal
        show={ImageView}
        onHide={() => setImageView(false)}
        className="image-view-modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div className="modal-heading">
          <h4>Image View</h4>
          <div className="close-icon">
            <img
              src="/Images/closeBlack.svg"
              onClick={() => setImageView(false)}
              alt=""
            />
          </div>
        </div>
        {/* Image View Modal */}
        {/* <img src={image} className="w-50" alt="" /> */}
        <div className="d-grid-row d-grid-modal-row mt-3">
          {image &&
            image?.length > 0 &&
            image?.map((i, index) => {
              return (
                <div key={i + index} className="mb-5">
                  <img className="w-100" src={IMAGE_URL + i} alt="im" />
                </div>
              );
            })}
        </div>
        {/* Image View Modal */}
      </DefaultModal>
      {/* Modal */}
    </div>
  );
};

export default RejectionModal;
