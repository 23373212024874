import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Link } from "react-router-dom";
import { getimportSubmission } from "../../../store/user/actions";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import { CSV__URL } from "../../../BaseUri/BaseUri";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
  justifyContent: "center",
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "0px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  // width: 100,
  // height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "none",
  width: "auto",
  height: "100%",
};
const ImportModal = ({ mClose, refreshNodeData }) => {
  const initialValues = {
    sample_category_node_transaction_id: "",
    files: "",
  };
  const [formValues, setFormValue] = useState(initialValues);
  const [files, setFiles] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [isDropzoneVisible, setDropzoneVisibility] = useState(true);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const importSubmission = useSelector((state) => state.importSubmission);
  const dispatch = useDispatch();
  const { getRootProps, getInputProps } = useDropzone({
    accept: [".csv"],
    multiple: false,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      if (acceptedFiles.length > 0) {
        // Assuming you only want to display the first uploaded image
        setUploadedImage(acceptedFiles[0]);
        setDropzoneVisibility(false); // Hide the dropzone
      }
    },
  });
  const showDropzone = () => {
    setUploadedImage(null); // Clear the uploaded image
    setDropzoneVisibility(true); // Show the dropzone again
  };
  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
    </div>
  ));

  const handleChange = (name, value) => {
    let data = { ...formValues };
    data[name] = value;
    setFormValue(data);
  };

  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;
    setFiles([...selectedFiles]);
  };

  const handleSubmit = (e, name, value) => {
    setError(null);
    setLoading(true);
    let check = validate(formValues);
    let data = { ...initialValues };
    data[name] = value;
    setFormErrors(check.errors);
    if (check.isValid && files.length > 0) {
      setLoading(false);
      const formData = new FormData();
      if (files.length === 1) {
        formData.append("file", files[0]);
      }
      formData.append("message", formValues.message);
      formData.append("react", "reactData");
      dispatch(getimportSubmission(formData));
    }
  };

  useEffect(() => {
    if (importSubmission.isSuccess) {
      refreshNodeData();
      mClose(false);
    }
  }, [importSubmission]);
  // validation-start
  useEffect(() => {
    if (Object.keys(formErrors).length > 0) {
    }
  }, [formErrors]);

  const validate = (values) => {
    let isValid = true;
    const errors = {};
    // if (!values.message) {
    //     isValid = false;
    //     errors.message = "message is required ";
    // }
    if (!files.length) {
      isValid = false;
      errors.files = "files is required";
    }
    return {
      isValid,
      errors,
    };
  };
  return (
    <div className="mt-4">
      {/* <h6>Message</h6> */}
      <label htmlFor="" className="labelx">
        Message
      </label>
      <div className="card_box">
        <p>Please upload CSV file</p>
      </div>
      <div>
        <div {...getRootProps({ className: "upload-file" })}>
          <input {...getInputProps()} multiple onChange={handleFileChange} />
          <aside style={thumbsContainer}>
            {files.length === 0 ? (
              <div>
                <img src="/Images/cloud.svg" />
                <h5>Upload Image</h5>
                <p>
                  Drag and Drop file here Or <b>Choose file</b>
                </p>
                <small>Support formats: JPG, PNG | Minimum size: 5 MB</small>
              </div>
            ) : (
              <div className="up_file">
                <img src="/Images/file_icon.svg" />
              </div>
            )}
            {thumbs}
          </aside>
        </div>

        <span className="error-msg">{formErrors.files}</span>
      </div>
      <div className="download_file new-download mt-3">
        <Link target="_blank" to={CSV__URL + "submission_import_sample_data"}>
          <img src="/Images/download.svg" /> Download Sample
        </Link>
      </div>
      <div className="mx-auto cente-btn text-center mt-3">
        <button className="dark-but" type="submit" onClick={handleSubmit}>
          Submit
        </button>
      </div>
    </div>
  );
};

export default ImportModal;
