import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IdealConsumptionsAddActions } from "../../store/user/actions";
import Loader from "../../component/Loader";
import DoneIcon from "@mui/icons-material/Done";
import IMG_DEFAULT from "../../no-image.jpg";
import { Button, Select } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { getToken } from "../../util/Common";
import { toast } from "react-toastify";
import { BASE_URL, IMAGE_URL } from "../../BaseUri/BaseUri";
import InfiniteScroll from "react-infinite-scroll-component";

const AddConsumptionProduct = () => {
  const dispatch = useDispatch();
  const { id, style_id } = useParams();

  const navigate = useNavigate();

  const IdealConsumptionAddProduct = useSelector(
    (state) => state.IdealConsumptionAddProduct
  );

  const [filtersku, setFilterSku] = useState("");

  const [isFilterActive, setIsFilterActive] = useState(false);

  const [items, setItems] = useState([]);

  // useEffect(() => {
  //   if (style_id || id) {
  //     dispatch(
  //       IdealConsumptionsAddActions({
  //         sample_request_id: style_id,
  //         product_sub_group_id: id,
  //         page: 1,
  //       })
  //     );
  //   }
  // }, [dispatch, style_id, id]);

  // console.log(
  //   "IDEAL CONSUMPTION ADD DATA: ",
  //   IdealConsumptionAddProduct?.data?.product
  // );

  const [ids, setIds] = useState([]);
  const [isClicked, setIsClicked] = useState(false);

  // useEffect(() => {
  //   if (
  //     IdealConsumptionAddProduct &&
  //     IdealConsumptionAddProduct?.isSuccess === true &&
  //     !isClicked
  //   ) {
  //     IdealConsumptionAddProduct &&
  //       IdealConsumptionAddProduct?.data?.product?.forEach((i) => {
  //         if (i.select === true && !ids.includes(i.id)) {
  //           setIds((prev) => [...prev, i.id]);
  //         }
  //       });
  //   }
  // }, [IdealConsumptionAddProduct, ids, isClicked]);

  // console.log("ID: ", ids);

  const handleImg = (id) => {
    setIsClicked(true);
    if (!ids.includes(id)) {
      setIds((prev) => [...prev, id]);
    } else {
      let filterIds = ids.filter((i) => i !== id);
      setIds(filterIds);
    }
  };

  const [isLoading, setIsLoading] = useState(false);

  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  const handleSubmit = async () => {
    const formData = new FormData();
    ids.forEach((i, index) => {
      formData.append(`product_ids[${index}]`, i);
    });

    formData.append("request_id", style_id);
    formData.append("product_sub_group_id", id);

    setIsLoadingSubmit(true);
    await axios({
      method: "POST",
      url: BASE_URL + `/sample/submit_ideal_consumption_product`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      data: formData,
    })
      .then((response) => {
        if (response?.data?.status === 0) {
          toast.error(response?.data?.message);
          setIsLoadingSubmit(false);
        } else if (response?.data?.status === 1) {
          toast.success("Data Saved Successfully");
          setIsLoadingSubmit(false);
          navigate(-1);
        }
        // console.log(response);
        // toast.success(response?.data?.message);
      })
      .catch((error) => {
        setIsLoadingSubmit(false);
        // console.log(error.response.data.message);
        toast.error(error?.response?.data?.message);
      });
  };

  // console.log("IDS: ", ids, id, style_id);

  // console.log(
  //   "IDEAL CONSUMPTION PRODUCT ID: ",
  //   IdealConsumptionAddProduct?.data
  // );

  // const [ideal__data, setIdeal__data] = useState([]);

  // console.log(IdealConsumptionAddProduct);

  useEffect(() => {
    if (
      IdealConsumptionAddProduct &&
      IdealConsumptionAddProduct?.isSuccess === true
    ) {
      setItems(
        IdealConsumptionAddProduct && IdealConsumptionAddProduct?.data?.product
      );
    }
  }, [IdealConsumptionAddProduct, filtersku]);

  // console.log("SKUS: ", skuObjects);

  const handleInputChange = (value) => {
    setFilterSku(value);
    setIsFilterActive(true);
    dispatch(
      IdealConsumptionsAddActions({
        sample_request_id: style_id,
        product_sub_group_id: id,
        sku: value,
        page: 1,
      })
    );
  };

  const handleClear = () => {
    setPage(1); // Reset the page
    setItems([]); // Clear the items array
    setFilterSku(""); // Clear the SKU filter
    setIsFilterActive(false);
    // dispatch(
    //   IdealConsumptionsAddActions({
    //     sample_request_id: style_id,
    //     product_sub_group_id: id,
    //     page: 1,
    //   })
    // );
  };

  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);

  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (!isFilterActive) {
      loadMoreData();
      setIsFilterActive(false);
    }
  }, [page, isFilterActive]);

  const loadMoreData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/sample/list_ideal_consumption_product`,
        {
          sample_request_id: style_id,
          product_sub_group_id: id,
          page: page,
        },
        {
          headers: { Authorization: `Bearer ${getToken()}` },
        }
      );

      const newItems = response.data?.data?.product || [];
      // setItems((prevItems) => [...prevItems, ...newItems]);
      setItems((prevItems) => {
        // Create a map of existing items by a unique property (e.g., `id`)
        const existingIds = new Set(prevItems.map((item) => item.id));
        // Filter new items to ensure only unique ones are added
        const uniqueNewItems = newItems.filter(
          (item) => !existingIds.has(item.id)
        );
        // Merge the unique new items with previous items
        return [...prevItems, ...uniqueNewItems];
      });
      setHasMore(newItems.length > 0); // stop loading if no new items

      response.data?.data?.product?.forEach((i) => {
        if (i.select === true && !ids.includes(i.id)) {
          setIds((prev) => [...prev, i.id]);
        }
      });

      const skuObjects =
        response.data?.data?.sku?.map((item) => ({
          label: item,
          value: item,
        })) || [];
      setOptions(skuObjects);
    } catch (error) {
      toast.error("Failed to load more data.");
    }
    setIsLoading(false);
  };

  // console.log("DATA IDEAL: ", IdealConsumptionAddProduct);

  // console.log("IDS: ", ids);

  return (
    <div>
      <div className="d-flex align-items-baseline justify-content-between pb-2">
        <div className="heading">
          <h2>Add Consumption Product</h2>
        </div>
        <div className="download-but"></div>
      </div>
      <div className="ideal-consumption-wrapper">
        <div className="row">
          <div className="col-md-4 col-lg-4 col-12 mb-2">
            <label className="select-label-bold">SKU</label>
            <Select
              placeholder="SKU"
              loading={IdealConsumptionAddProduct?.isLoading}
              options={options}
              value={filtersku}
              onChange={(value) => handleInputChange(value)}
            />
          </div>
          <div className="col-md-4 col-lg-4 col-12 mb-2 d-flex flex-column align-item-start justify-content-end">
            <div className="clearDatas mb-3">
              <Link onClick={() => handleClear()} className="clear_data">
                Clear Data
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="images-add-consumption-grid-row">
        {IdealConsumptionAddProduct?.isLoading ? (
          <div className="loader_icons">
            <Loader />
          </div>
        ) : (
          <div>
            <InfiniteScroll
              dataLength={items?.length}
              next={() => setPage((prev) => prev + 1)}
              hasMore={hasMore}
              loader={
                <div className="d-flex flex-column align-items-center justify-content-center mt-5">
                  {isLoading ? <Loader /> : null}
                </div>
              }
              endMessage={<p>No more items to load</p>}
            >
              <div className="add-ideal-consumption-grid-row">
                {items &&
                  items?.length > 0 &&
                  items?.map((i, index) => (
                    <Fragment key={index}>
                      <div
                        className="add-ideal-consumption-grid-col"
                        onClick={() => handleImg(i.id)}
                      >
                        {ids.includes(i.id) && (
                          <div>
                            <DoneIcon fontSize="12px" />{" "}
                          </div>
                        )}

                        <img
                          style={{
                            border:
                              ids.includes(i.id) &&
                              "3px solid rgba(0, 54, 245, 1)",
                          }}
                          src={
                            !i.image
                              ? IMG_DEFAULT
                              : IMAGE_URL + i?.image?.thumbnail
                          }
                          alt=""
                        />
                        <p>{i.sku}</p>
                      </div>
                    </Fragment>
                  ))}
              </div>
            </InfiniteScroll>
            <div className="submit-cta-ideal-consumption">
              <Button onClick={handleSubmit}>
                {isLoadingSubmit ? <Loader /> : "submit"}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddConsumptionProduct;
