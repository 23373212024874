import React, { useState } from "react";
import RequestSelect from "./component/RequestSelect";
import RequestTable from "./component/RequestTable";
import { Helmet } from "react-helmet";
import { CSV__URL } from "../../BaseUri/BaseUri";

const SampleRequestParameter = () => {
  const [selectBox, setSelectBox] = useState({});

  const downloadExcel = () => {
    let url = CSV__URL + "sample-caterory-node-parameter";

    // Add sample_category_id if it exists
    if (selectBox.sample_category_id) {
      url += `?sample_category_id=${selectBox.sample_category_id}`;
    }

    // Add sample_node_id if it exists
    if (selectBox.sample_node_id) {
      url += `${selectBox.sample_category_id ? "&" : "?"}sample_node_id=${
        selectBox.sample_node_id
      }`;
    }

    // Add trail if it exists
    if (selectBox.trail) {
      url += `${
        selectBox.sample_category_id || selectBox.sample_node_id ? "&" : "?"
      }trail=${selectBox.trail}`;
    }

    // Add parameters if they exist
    // if (selectBox.parameter) {
    //   selectBox.parameter.forEach((param, index) => {
    //     url += `${
    //       index === 0 &&
    //       !selectBox.sample_category_id &&
    //       !selectBox.sample_node_id &&
    //       !selectBox.trail
    //         ? "?"
    //         : "&"
    //     }${param.id}=${param.value}`;
    //   });
    // }

    window.location.href = url;

    // console.log("URL: ", url);

    // console.log(url);
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Parameter Relationship | Sample Dashboard</title>
      </Helmet>

      <div className="d-flex align-items-baseline justify-content-between pb-2">
        <div className="heading">
          <h2>Sample Category Node Parameter Relationship</h2>
        </div>
        <div className="download-but">
          <button onClick={() => downloadExcel()}>
            <img src="Images/document-download.svg" />
            Export CSV
          </button>
        </div>
      </div>
      <RequestSelect filterValue={setSelectBox} />
      <RequestTable selectBox={selectBox} />
    </div>
  );
};

export default SampleRequestParameter;
