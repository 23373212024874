// import "./App.css";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import LoginPage from "./pages/LoginPage/LoginPage";
import SplashPage from "./pages/SplashPage/SplashPage";
import { ThemeProvider } from "styled-components";
import theme from "./theme";
import NavbarSidebarAdmin from "./component/NavbarSidebarMosque/NavbarSidebarAdmin";
import Dashboard from "./pages/Dashboard/Dashboard";
// import "./css/header.css";
// import "./css/calendar.css";
// import "./css/dashboard.css";
// import "./css/nodewise.css";
// import "./css/collections.css";
// import "./css/pending.css";
// import "./css/nodebox.css";
// import "./css/requestAdd.css";
// import "reactflow/dist/style.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Collections from "./pages/Collections/Collections";
import Calendar from "./pages/Calendar/Calendar";
import Workload from "./pages/Workload/Workload";
import MyPendingTask from "./pages/MyPendingTask/MyPendingTask";
import SampleCategory from "./pages/SampleCategory/SampleCategory";
import SubmissionScreen from "./pages/SubmissionScreen/SubmissionScreen";
import Settings from "./pages/Settings/Settings";
import AllPendingTask from "./pages/AllPendingTask/AllPendingTask";
import NodeWiseTask from "./pages/NodeWiseTask/NodeWiseTask";
import PublicRoute from "./util/PublicRoute";
import PrivateRoute from "./util/PrivateRoute";
import NodeWorkload from "./pages/NodeWorkload/NodeWorkload";
import SampleNodes from "./pages/SampleNodes/SampleNodes";
import Gallery from "./pages/Gallery/Gallery";
import History from "./pages/History/History";
import SubmissionForm from "./pages/SubmissionForm/SubmissionForm";
import ViewSubmissionDetails from "./pages/ViewSubmissionDetails/ViewSubmissionDetails";
import SelectNode from "./pages/SelectNode/SelectNode";
import CustomBigCalendar from "./pages/Calendar/component/CustomBigCalendar";
import Logs from "./pages/Logs/Logs";
import RejectionRequest from "./pages/RejectionRequest/RejectionRequest";
import NodeTargets from "./pages/NodeTargets/NodeTargets";
import SampleRequestAdd from "./pages/SampleRequestAdd/SampleRequestAdd";
import SampleRequestNodes from "./pages/SampleRequestNodes/SampleRequestNodes";
import SampleRequestParameter from "./pages/SampleRequestParameter/SampleRequestParameter";
import GarmentsFit from "./pages/GarmentsFit/GarmentsFit";
import CostRangeMaster from "./pages/CostRangeMaster/CostRangeMaster";
import IdealConsumption from "./pages/IdealConsumption/IdealConsumption";
import CreateFit from "./pages/CreateFit/CreateFit";
import MasterFit from "./pages/MasterFit/MasterFit";
import ImageColorAddition from "./pages/ColorAddition/ImageColorAddition";
import ImageColorSelection from "./pages/ColorAddition/ImageColorSelection";
import ProductData from "./pages/ColorAddition/ProductData";
import BatchLevel from "./pages/ColorAddition/BatchLevel";
import AddConsumptionProduct from "./pages/IdealConsumption/AddConsumptionProduct";
import FitVariations from "./pages/GarmentsFit/FitVariations";
import MasterFitSc from "./pages/GarmentsFit/MasterFit";
import NewLaunchesConsolidated from "./pages/NewLaunches/NewLaunchesConsolidated";

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route element={<PublicRoute />}>
            <Route path="/login" element={<LoginPage />} />
          </Route>
          <Route path="/splash" element={<SplashPage />} />
          <Route element={<PrivateRoute />}>
            <Route
              element={
                <NavbarSidebarAdmin>
                  <Outlet />
                </NavbarSidebarAdmin>
              }
            >
              <Route path="/" element={<NodeWiseTask />} />
              <Route path="/node-wise-task" element={<Dashboard />} />
              <Route path="/select-node" element={<SelectNode />} />
              <Route path="/node-workload/:ids" element={<NodeWorkload />} />
              <Route
                path="/sample-node/:id/:status?"
                element={<SampleNodes />}
              />
              <Route path="/gallery/:id" element={<Gallery />} />
              <Route path="/collections" element={<Collections />} />
              <Route path="/calendar" element={<Calendar />} />
              <Route path="/calendar-new" element={<CustomBigCalendar />} />
              <Route path="/logs" element={<Logs />} />
              <Route path="/rejection-request" element={<RejectionRequest />} />
              <Route path="/workload" element={<Workload />} />
              <Route path="/pending-task" element={<MyPendingTask />} />
              <Route path="/all-pending-task" element={<AllPendingTask />} />
              <Route path="/sample-category" element={<SampleCategory />} />
              <Route path="/submission-screen" element={<SubmissionScreen />} />
              <Route
                path="/new-launches-consolidated"
                element={<NewLaunchesConsolidated />}
              />
              <Route
                path="/view-submission-details/:id"
                element={<ViewSubmissionDetails />}
              />
              <Route
                path="/sample-request-add"
                element={<SampleRequestAdd />}
              />
              <Route
                path="/sample-request-edit/:id?"
                element={<SampleRequestAdd />}
              />
              <Route
                path="/sample-request-nodes/:id/:cat_id"
                element={<SampleRequestNodes />}
              />
              <Route
                path="/sample-request-parameter-relationship"
                element={<SampleRequestParameter />}
              />
              <Route path="/garments-fit" element={<GarmentsFit />} />
              <Route path="/cost-range-master" element={<CostRangeMaster />} />
              <Route
                path="/ideal-consumption/:id?/:isTrue?"
                element={<IdealConsumption />}
              />
              <Route
                path="/add-consumption-product/:id?/:style_id?"
                element={<AddConsumptionProduct />}
              />
              <Route path="/node-targets" element={<NodeTargets />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/history/:id" element={<History />} />
              <Route path="/submission-form/:id" element={<SubmissionForm />} />
              <Route path="/create-new-fit/:id?" element={<CreateFit />} />
              <Route path="/master-fit/:id?" element={<MasterFit />} />
              <Route
                path="/image-color-addition/:id"
                element={<ImageColorAddition />}
              />
              <Route
                path="/image-color-selection/:id"
                element={<ImageColorSelection />}
              />
              <Route
                path="/product-data/:id/:form_type"
                element={<ProductData />}
              />
              <Route
                path="/batch-level/:id/:form_type"
                element={<BatchLevel />}
              />
              <Route path="/fit-variation/:id" element={<FitVariations />} />
              <Route path="/masterfit" element={<MasterFitSc />} />
            </Route>
          </Route>
        </Routes>
      </ThemeProvider>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
