import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { Link } from "react-router-dom";
import { getfilterData } from "../../../store/user/actions";
import { useDispatch, useSelector } from "react-redux";
import { convertDropdown_Alternate } from "../../CreateFit/CreateFit";
import axios from "axios";
import { getToken } from "../../../util/Common";
import { toast } from "react-toastify";
import { BASE_URL } from "../../../BaseUri/BaseUri";

const RequestSelect = ({
  setIdealConsumption_Arr,
  setProduct_group_id,
  setLoading,
  isActives,
  sample_req_number,
  style_number,
}) => {
  const dispatch = useDispatch();
  const filterData = useSelector((state) => state.filterData);

  const IdealConsumptionList = useSelector(
    (state) => state.IdealConsumptionList
  );

  const [SampleRequest_Arr, setSampleRequest_Arr] = useState([]);
  const [style_Arr, setStyle_Arr] = useState([]);

  useEffect(() => {
    dispatch(getfilterData({}));
  }, [dispatch]);

  const [sample_request_id, setSample_request_id] = useState("");
  const [style_id, setStyle_id] = useState("");

  const handleSampleRequest = (val) => {
    // console.log("VALUE: ", val);
    setSample_request_id(val);
    localStorage.setItem("sample_request_id", val);
  };

  const handleStyleRequest = (val) => {
    // console.log("VALUE: ", val);
    setStyle_id(val);
    localStorage.setItem("style_id", val);
  };

  useEffect(() => {
    if (sample_request_id !== "" || localStorage.getItem("sample_request_id")) {
      setLoading(true);
      axios({
        method: "POST",
        url: BASE_URL + `/sample/list_ideal_consumption`,
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
        data: {
          sample_request_id:
            sample_request_id || localStorage.getItem("sample_request_id"),
          style_type_id: style_id,
        },
      })
        .then((response) => {
          console.log("RESPONSE: ", response?.data);
          if (response?.data?.status === 0) {
            // toast.error(response?.data?.message);

            const errorData = response?.data?.data?.error;

            let message = "An unknown error occurred.";

            if (errorData) {
              if (typeof errorData === "object") {
                // Iterate through each key in the error object
                const messages = Object.keys(errorData).map((key) => {
                  // Join array elements for each key into a string
                  return Array.isArray(errorData[key])
                    ? errorData[key].join(", ")
                    : errorData[key];
                });

                // Combine all error messages into a single string separated by a semicolon
                message = messages.join("; ");
              } else {
                message =
                  response?.data?.message || "An unexpected error occurred.";
              }
            }

            toast.error(message);

            setLoading(false);
          } else if (response?.data?.status === 1) {
            // console.log("RESPONSE: ", response?.data);
            setIdealConsumption_Arr(response?.data?.data?.list);
            // console.log(
            //   "PRODUCT GROUP ID: ",
            //   response?.data?.data?.list,
            //   sample_request_id
            // );
            setProduct_group_id(sample_request_id);
            setLoading(false);
          }
          // console.log(response);
          // toast.success(response?.data?.message);
        })
        .catch((error) => {
          // console.log(error.response.data.message);
          toast.error(error?.response?.data?.message);
          setLoading(false);
        });
      // dispatch(
      //   IdealConsumptionsListActions({
      //     sample_request_id: sample_request_id,
      //     style_type_id: style_id,
      //   })
      // );
    }
  }, [
    dispatch,
    sample_request_id,
    style_id,
    setIdealConsumption_Arr,
    setProduct_group_id,
    setLoading,
  ]);

  useEffect(() => {
    if (filterData && filterData?.isSuccess === true) {
      let sample_Request_arr = convertDropdown_Alternate(
        filterData && filterData?.data?.sample_request
      );
      setSampleRequest_Arr(sample_Request_arr);

      let styleArr = convertDropdown_Alternate(
        filterData && filterData?.data?.style
      );
      setStyle_Arr(styleArr);
    }
  }, [filterData]);

  // console.log("FILTER DATA: ", SampleRequest_Arr);
  // console.log("IDEAL CONSUMPTION DATA: ", IdealConsumptionList);

  // useEffect(() => {
  //   if (IdealConsumptionList && IdealConsumptionList?.isSuccess === true) {
  //     setIdealConsumption_Arr(
  //       IdealConsumptionList && IdealConsumptionList?.data
  //     );
  //     setProduct_group_id(sample_request_id);
  //   }
  // }, [
  //   IdealConsumptionList,
  //   setIdealConsumption_Arr,
  //   setProduct_group_id,
  //   sample_request_id,
  // ]);

  const filterName = (arr, id) => {
    const filterX = arr.filter((i) => parseInt(i.id) === parseInt(id));
    return filterX[0]?.label;
  };

  return (
    <div>
      {/* <RequestSelect/> */}
      <div className="row ideal-consumption-row">
        <div className="col-md-4 col-lg-4 col-12 mb-2">
          <label className="select-label-bold">
            Select Sample Request Number
          </label>
          {isActives ? (
            <input
              type="text"
              name=""
              id=""
              className="input-disabled"
              value={filterName(SampleRequest_Arr, sample_req_number)}
              disabled={true}
            />
          ) : (
            <Select
              // defaultValue="Trial"
              value={
                sample_request_id || localStorage.getItem("sample_request_id")
              }
              defaultValue="Select Sample Request Number"
              options={SampleRequest_Arr}
              loading={filterData?.isLoading}
              onChange={(value) => handleSampleRequest(value)}
              showSearch
              filterOption={(input, userOption) =>
                ((userOption?.label).toLowerCase() ?? "").includes(
                  input.toLowerCase()
                )
              }
              filterSort={(userOption) =>
                (userOption?.label ?? "")
                  .toLowerCase()
                  .localeCompare((userOption?.label ?? "").toLowerCase())
              }
            />
          )}
        </div>
        {/* {isActives && ( */}
        {isActives && (
          <div className="col-md-4 col-lg-4 col-12 mb-2">
            <label className="select-label-bold">Select Style Number</label>
            {
              isActives ? (
                <input
                  type="text"
                  name=""
                  id=""
                  className="input-disabled"
                  value={filterName(style_Arr, style_number)}
                  disabled={true}
                />
              ) : null
              // <Select
              //   defaultValue="Select Style Number"
              //   options={style_Arr}
              //   value={style_id}
              //   loading={filterData?.isLoading}
              //   onChange={(value) => handleStyleRequest(value)}
              //   showSearch
              //   filterOption={(input, userOption) =>
              //     ((userOption?.label).toLowerCase() ?? "").includes(
              //       input.toLowerCase()
              //     )
              //   }
              //   filterSort={(userOption) =>
              //     (userOption?.label ?? "")
              //       .toLowerCase()
              //       .localeCompare((userOption?.label ?? "").toLowerCase())
              //   }
              // />
            }
          </div>
        )}
        {/* )} */}
        <div className="col-md-4 col-lg-4 col-12 d-flex align-items-md-center">
          <div className="clearDatas">
            <Link
              onClick={() => {
                setSample_request_id("");
                setStyle_id("");
                localStorage.removeItem("sample_request_id");
              }}
              className="clear_data"
            >
              Clear Data
            </Link>
          </div>
        </div>
        {/* Sample Category Node Parameter Relationship */}
      </div>
    </div>
  );
};

export default RequestSelect;
