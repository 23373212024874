import React, { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import DataTable from "../../component/DataTable/Table.component";
import { useDispatch, useSelector } from "react-redux";
import { getNewLaunchesReportActions } from "../../store/user/actions";
import { Pagination } from "antd";
import Loader from "../../component/Loader";

const Header = [
  "Sr.No.",
  "Picture",
  "Basic Info",
  "Phase",
  "Total Qty",
  "Colors and Qty",
  <>
    Inventory Date <br /> and Qty
  </>,
  <>
    Qty at <br /> Nodes Currently
  </>,
  <>
    Sales and <br /> Returns
  </>,
];

const NewLaunchesConsolidated = () => {
  const dispatch = useDispatch();

  const newLaunchesConsolidated = useSelector(
    (state) => state.newLaunchesConsolidated
  );

  const [current, setCurrent] = useState(1);

  useEffect(() => {
    dispatch(getNewLaunchesReportActions({ page: 1 }));
  }, [dispatch]);

  const onChange = (val) => {
    setCurrent(val);
    dispatch(getNewLaunchesReportActions({ page: val }));
  };

  // console.log(
  //   "NEW LAUNCHES CONSOLIDATED REPORT: ",
  //   newLaunchesConsolidated?.data?.list
  // );

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>New Launches Consolidated Report | Sample Dashboard</title>
      </Helmet>
      <div className="d-flex flex-row align-items-center justify-content-between">
        <div className="heading">
          <h2>New Launches Consolidated Report</h2>
        </div>
        <div className="download-but">
          <button>
            <img src="Images/document-download.svg" alt="" />
            Export csv
          </button>
        </div>
      </div>

      <div className="table-wrapper-pagination mt-4">
        <div className="table-container-pagination">
          {newLaunchesConsolidated?.isLoading ? (
            <div className="d-flex-row">
              <Loader />
            </div>
          ) : (
            <DataTable
              header={Header}
              new_launches_arr={newLaunchesConsolidated?.data?.list}
              // new_launches_arr={xyz}
              // garments={garmentFitList && garmentFitList?.data?.data}
              // itemsPerPage={10}
              // current={page}
            />
          )}
        </div>
      </div>

      <Pagination
        current={current}
        onChange={onChange}
        total={newLaunchesConsolidated?.data?.total}
      />
    </Fragment>
  );
};

export default NewLaunchesConsolidated;
