import React, { useEffect, useState } from "react";
import { Button, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getfilterData } from "../../../store/user/actions";
import { convertDropdown_Alternate } from "../../CreateFit/CreateFit";
import { toast } from "react-toastify";
import axios from "axios";
import { getToken } from "../../../util/Common";
import Loader from "../../../component/Loader";
import { BASE_URL } from "../../../BaseUri/BaseUri";

const selection_box_arr = [
  {
    id: 1,
    value: 1,
    label: "Yes",
  },
  {
    id: 0,
    value: 0,
    label: "No",
  },
];

const AddProductIdeal = ({
  request_id,
  product_ids,
  product_sub_group_id,
  mClose,
  prefill_Product_Ideal,
  handleRefreshed,
}) => {
  const dispatch = useDispatch();
  const filterData = useSelector((state) => state.filterData);

  useEffect(() => {
    dispatch(getfilterData({}));
  }, [dispatch]);

  const [process_node, setProcess_node] = useState([]);
  const [consumption_type_arr, setConsumption_type_arr] = useState([]);

  // console.log("FILTER DATA: ", filterData);

  useEffect(() => {
    if (filterData && filterData?.isSuccess === true) {
      let process_node_arr = convertDropdown_Alternate(
        filterData && filterData?.data?.process_node
      );
      setProcess_node(process_node_arr);

      let consumption_type_arr = convertDropdown_Alternate(
        filterData && filterData?.data?.consumption_type
      );
      setConsumption_type_arr(consumption_type_arr);
    }
  }, [filterData]);

  const [critical, setCritical] = useState("");
  const [process_node_id, setprocess_node_id] = useState("");
  const [consumption_type, setConsumption_type] = useState("");
  const [for_mrn, setfor_mrn] = useState("");
  const [grouping, setgrouping] = useState("");

  const handleCritical = (val) => {
    // console.log("CRITICAL: ", val);
    setCritical(val);
  };

  const handleProcessNode = (val) => {
    // console.log("VAL: ", val);
    setprocess_node_id(val);
  };

  const handleConsumptionType = (val) => {
    // console.log(val);
    setConsumption_type(val);
    // let actual_val = consumption_type_arr.filter((i) => i.id !== val);
    // console.log("ACTUAL VAL: ", actual_val[0].label.split(" - ")[1]);
    // setConsumption_type(actual_val[0].label.split(" - ")[0]);
  };

  const handleMRN = (val) => {
    setfor_mrn(val);
    // console.log("MRN: ", val);
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleIdealSubmit = async () => {
    const formData = new FormData();

    formData.append("request_id", request_id);
    formData.append("product_ids[0]", product_sub_group_id);
    formData.append("product_sub_group_id", product_ids);
    formData.append(`critical`, critical);
    formData.append("process_node_id", process_node_id);
    formData.append("consumption_type", consumption_type);
    formData.append("uom", 0);
    formData.append("for_mrn", for_mrn);
    formData.append("grouping", grouping);

    setIsLoading(true);
    await axios({
      method: "POST",
      url: BASE_URL + `/sample/submit_ideal_consumption_product`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      data: formData,
    })
      .then((response) => {
        if (response?.data?.status === 0) {
          toast.error(response?.data?.message);
          setIsLoading(false);
        } else if (response?.data?.status === 1) {
          toast.success("Data Saved Successfully");
          handleRefreshed();
          setIsLoading(false);
          mClose(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  // console.log("PREFILL PRODUCT DATA: ", prefill_Product_Ideal);

  useEffect(() => {
    if (prefill_Product_Ideal) {
      setCritical(prefill_Product_Ideal?.critical);
      setprocess_node_id(prefill_Product_Ideal?.process_node_id);
      setConsumption_type(prefill_Product_Ideal?.consumption_type);
      setfor_mrn(prefill_Product_Ideal?.for_mrn);
      setgrouping(prefill_Product_Ideal?.grouping);
    }
  }, [prefill_Product_Ideal]);

  // console.log("SELECT BOX ARRAY: ", selection_box_arr);

  return (
    <div>
      <div>
        <label className="select-label-bold">Critical</label>
        <Select
          // defaultValue="Trial"
          value={
            parseInt(critical) === 1
              ? "Yes"
              : parseInt(critical) === 0
              ? "No"
              : ""
          }
          defaultValue="Critical"
          options={selection_box_arr}
          loading={filterData?.isLoading}
          onChange={(value) => handleCritical(value)}
          showSearch
        />
      </div>
      <div>
        <label className="select-label-bold">Process Node</label>
        <Select
          // defaultValue="Trial"
          value={process_node_id}
          defaultValue="Process Node"
          options={process_node}
          loading={filterData?.isLoading}
          onChange={(value) => handleProcessNode(value)}
          showSearch
        />
      </div>
      <div>
        <label className="select-label-bold">Consumption Type</label>
        <Select
          // defaultValue="Trial"
          value={
            parseInt(consumption_type) === 1
              ? "Variable Consumption - (1)"
              : parseInt(consumption_type) === 0
              ? "Fixed Consumption - (0)"
              : ""
          }
          defaultValue="Consumption Type"
          options={consumption_type_arr}
          loading={filterData?.isLoading}
          onChange={(value) => handleConsumptionType(value)}
          showSearch
        />
      </div>
      <div>
        <label className="select-label-bold">UOM</label> <br />
        <input
          className="input"
          type="text"
          name=""
          disabled
          value={0}
          id=""
          placeholder="UOM"
        />
      </div>
      <div>
        <label className="select-label-bold">For MRN use</label>
        <Select
          // defaultValue="Trial"
          // value={for_mrn}
          value={
            parseInt(for_mrn) === 1
              ? "Yes"
              : parseInt(for_mrn) === 0
              ? "No"
              : ""
          }
          defaultValue="For MRN use"
          options={selection_box_arr}
          loading={filterData?.isLoading}
          onChange={(value) => handleMRN(value)}
          showSearch
        />
      </div>
      <div>
        <label className="select-label-bold">Grouping</label> <br />
        <input
          className="input"
          type="number"
          name=""
          id=""
          placeholder="Grouping"
          value={grouping}
          onChange={(e) => setgrouping(e.target.value)}
        />
      </div>
      <div className="submit-cta">
        <Button onClick={handleIdealSubmit}>
          {isLoading ? <Loader /> : "SUBMIT"}
        </Button>
      </div>
    </div>
  );
};

export default AddProductIdeal;
