import React, { Fragment, useEffect, useState } from "react";
import Table from "../../../component/Table/Table";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getsubmissionDataDetails } from "../../../store/user/actions";
import Loader from "../../../component/Loader";
import UserNameImage from "../../../component/UserNameImage/UserNameImage";
import AudioPlayer from "./AudioPlayer";
import DefaultModal from "../../../component/DefaultModal/DefaultModal";
import { CSV__URL, IMAGE_URL } from "../../../BaseUri/BaseUri";

const ViewTable = () => {
  // const [current, setCurrent] = useState(3);
  const [tableData, setTableData] = useState([]);
  const { id } = useParams();
  const submissionDataDetails = useSelector(
    (state) => state.submissionDataDetails
  );
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [Image, setImage] = useState("");

  const handleImageOpen = (i) => {
    setImage(IMAGE_URL + i.file);
    setShow(!show);
  };

  useEffect(() => {
    dispatch(getsubmissionDataDetails(id));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const tables = submissionDataDetails.data.map((item, i) => {
      const audioPlayer = item.audio ? (
        <AudioPlayer audioSrc={IMAGE_URL + item.audio} />
      ) : (
        <div className="diseble_play">
          <i className="fa fa-play-circle" aria-hidden="true"></i>
        </div>
      );
      const rowData = [
        <div className="currnt-nodes">
          <UserNameImage
            width="40px"
            height="40px"
            margin="0 auto"
            name={item.user_name}
            image={item.user_image}
          />
          <h6>{item.node_name}</h6>
          <h6>{item.user_name}</h6>
        </div>,
        <h6>{item.submit_datetime}</h6>,
        <div>
          <h6> {parseInt(item.delay) < 0 ? 0 : item.delay} Days</h6>
        </div>,
        <div className="view_im">
          <img
            onClick={() => handleImageOpen(item)}
            src={IMAGE_URL + item.file}
            alt="im"
          />
        </div>,
        <div className="audo_io">{audioPlayer}</div>,
        <h6>{item.remark}</h6>,
        <div className="view_a">
          <Link
            style={{ pointerEvents: item.document === "" ? "none" : "initial" }}
            to={CSV__URL + "download_submission_document/" + item.id}
            target="blank"
          >
            View
          </Link>
        </div>,
      ];

      const templateTableData = [
        { heading: "Username" },
        { heading: "Submit Date" },
        { heading: "Delay Time" },
        { heading: "Image" },
        { heading: "Voice Note" },
        { heading: "Remark" },
        { heading: "Document" },
      ];

      return (
        <div key={i} className="position-relative mb-4">
          {submissionDataDetails.isLoading && (
            <div className="loader_bg">
              <Loader />
            </div>
          )}
          <div className="table-bg view_submission_table">
            <h4>Submission {i + 1}</h4>
            <Table
              gridTemplateColumnsHeader="15% 10% 10% 20% 10% 20% 15%"
              gridTemplateColumnsBody="15% 10% 10% 20% 10% 20% 15%"
              columns={templateTableData}
              data={[rowData]}
            />
          </div>
        </div>
      );
    });

    setTableData(tables);
  }, [submissionDataDetails]);

  return (
    <Fragment>
      <div>{tableData}</div> {/* Modal Component starts here */}
      <DefaultModal
        show={show}
        onHide={() => setShow(false)}
        className="remark-modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <img className="Image-row-submission" src={Image} alt="" />
      </DefaultModal>
      {/* Modal Component ends here */}
    </Fragment>
  );
};

export default ViewTable;
