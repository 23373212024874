const url = localStorage.getItem("sample__module__react__uri");

export const BASE_URL = url ? url : process.env.REACT_APP_API_URL;

export const IMAGE_URL = url
  ? url.replace("api", "image?link=")
  : `${process.env.REACT_APP_API_URL.replace("api", "image?link=")}`;

export const CSV__URL = url
  ? url.replace("api", "")
  : `${process.env.REACT_APP_API_URL.replace("/api", "")}`;
