import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getactiveNode, getinactiveNode } from "../../../store/user/actions";
import { toast } from "react-toastify";

const ToggleSwitch = ({ label, status, id, refreshNodeData }) => {
  const [isChecked, setChecked] = useState(status);
  const inactiveNode = useSelector((state) => state.inactiveNode);
  const activeNode = useSelector((state) => state.activeNode);

  const dispatch = useDispatch();

  const [isHit, setIsHit] = useState(false);

  const resetCheckbox = () => {
    setIsHit(true);
    if (isChecked == true) {
      dispatch(getinactiveNode(id));
    } else {
      dispatch(getactiveNode(id));
    }
    setChecked(!isChecked);
  };
  useEffect(() => {
    if ((inactiveNode.isSuccess || activeNode.isSuccess) && isHit) {
      // toast.success("ok");
      refreshNodeData();
      setIsHit(false);
    }
  }, [inactiveNode, activeNode, isHit]);

  return (
    <div className="custom-switch">
      {/* <p>{label}{" "} </p> */}
      <div className="toggle-switch">
        <input
          type="checkbox"
          className="checkbox"
          name={label}
          id={label}
          checked={isChecked}
          onChange={resetCheckbox}
          // {status?"":"disable"}
          // disabled={!isChecked}
        />
        <label className="label" htmlFor={label}>
          <span className="inner" />
          <span className="switch" />
        </label>
      </div>
    </div>
  );
};

export default ToggleSwitch;
