import { Button, Select } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetBatchLevelDataActions,
  getfilterData,
} from "../../store/user/actions";
import { convertDropdown } from "../CreateFit/CreateFit";
import { getToken } from "../../util/Common";
import { toast } from "react-toastify";
import axios from "axios";
import Loader from "../../component/Loader";
import DataTable from "../../component/DataTable/Table.component";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../BaseUri/BaseUri";

const Header = ["Sr.No.", "Pictures", "SKU", "Qty", "Size", "Colors"];

const BatchLevel = () => {
  const navigate = useNavigate();
  const { id, form_type } = useParams();
  const dispatch = useDispatch();

  const filterData = useSelector((state) => state.filterData);
  const BatchLevelData = useSelector((state) => state.BatchLevelData);

  const [product_group, setProduct_group] = useState("");
  const [location, setLocation] = useState([]);
  const [size, setSize] = useState([]);
  const [color, setColor] = useState([]);
  const [Main_cuts, setMain_cuts] = useState("");
  const [categories, setCategories] = useState([]);
  const [colors, setColors] = useState([]);
  const [location_id, setLocation_id] = useState("");
  const [isReadyToPreview, setIsReadyToPreview] = useState(false);

  useEffect(() => {
    dispatch(GetBatchLevelDataActions(id));
    dispatch(getfilterData());
  }, [dispatch, id]);

  useEffect(() => {
    if (filterData && filterData.isSuccess) {
      const locationOptions = convertDropdown(filterData.data.location);
      setLocation(locationOptions);
    }
  }, [filterData]);

  useEffect(() => {
    if (BatchLevelData && BatchLevelData.isSuccess) {
      setProduct_group(BatchLevelData.data.product_group);

      const sizeOptions = BatchLevelData?.data?.size
        ? convertDropdown(BatchLevelData.data.size)
        : [];
      setSize(sizeOptions);

      const colorOptions = convertDropdown(BatchLevelData.data.color);
      setColor(colorOptions);

      setCategories(
        sizeOptions.map((sizeItem) => ({
          id: sizeItem.id,
          ratio: 0,
        }))
      );

      setColors(
        colorOptions.map((colorItem) => ({
          id: colorItem.id,
          ratio: 0,
        }))
      );
    }
  }, [BatchLevelData]);

  const handleLocation = (val) => {
    setLocation_id(val);
    validateForm();
  };

  const handleInputChange = (val, sizeId) => {
    setCategories((prevCategories) =>
      prevCategories.map((category) =>
        category.id === sizeId ? { ...category, ratio: Number(val) } : category
      )
    );
    validateForm();
  };

  const handleInputColor = (val, colId) => {
    setColors((prevColors) =>
      prevColors.map((color) =>
        color.id === colId ? { ...color, ratio: Number(val) } : color
      )
    );
    validateForm();
  };

  const handleMainCutsChange = (e) => {
    setMain_cuts(e.target.value);
    validateForm();
  };

  const validateForm = useCallback(() => {
    // console.log("Validating Form...");
    // console.log("Location ID:", location_id);
    // console.log("Main Cuts:", Main_cuts);
    // console.log("Categories:", categories);
    // console.log("Colors:", colors);

    const isLocationValid = location_id !== "";
    const isMainCutsValid = Main_cuts !== "";
    // const areSizesValid = categories.every((category) => category.ratio > 0);
    // const areColorsValid = colors.every((color) => parseInt(color.ratio) > 0);

    // console.log("Is Location Valid:", isLocationValid);
    // console.log("Is Main Cuts Valid:", isMainCutsValid);
    // console.log("Are Sizes Valid:", areSizesValid);
    // console.log("Are Colors Valid:", areColorsValid);

    // setIsReadyToPreview(
    //   isLocationValid && isMainCutsValid && areSizesValid && areColorsValid
    // );
    setIsReadyToPreview(isLocationValid && isMainCutsValid);
  }, [Main_cuts, location_id]);

  // console.log("Are Colors Valid:", colors);

  const [isLoading, setIsLoading] = useState(false);
  const [sku_preview, setSKU_Preview] = useState([]);

  const handlePreviewBatchSKU = async () => {
    if (!isReadyToPreview) {
      toast.error("Please Fill all the Fields");
      return;
    }

    const obj = {
      location_id,
      number_of_cut: Main_cuts,
      color: colors,
      size: categories,
    };

    setIsLoading(true);

    try {
      const response = await axios.post(
        `${BASE_URL}/sample_request/batch_level_perview/${id}`,
        obj,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );

      if (response?.data?.status === 1) {
        setSKU_Preview(response.data.data);
        toast.success("Preview Successfully!");
      } else {
        toast.error(response.data.message || "Failed to submit data.");
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to submit data.");
    } finally {
      setIsLoading(false);
    }
  };

  const [isManufacture_Loading, setIsManufacture_Loading] = useState(false);

  const handleCreateManufacturOrder = async () => {
    if (form_type === 0) {
      if (!isReadyToPreview) {
        toast.error("Please Fill all the Fields");
        return;
      }
    }

    const obj = {
      location_id,
      number_of_cut: Main_cuts,
      color: colors,
      size: categories,
    };

    console.log("OBJECT VALUE: ", obj);

    setIsManufacture_Loading(true);

    try {
      const response = await axios.post(
        `${BASE_URL}/sample_request/batch_lavel_submit/${id}`,
        obj,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );

      if (response?.data?.status === 1) {
        toast.success(response.data.message);
        navigate("/pending-task");
      } else {
        toast.error(response.data.message || "Failed to submit data.");
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to submit data.");
    } finally {
      setIsManufacture_Loading(false);
    }
  };

  useEffect(() => {
    if (
      BatchLevelData &&
      BatchLevelData?.isSuccess &&
      BatchLevelData?.data?.preview_data !== null
    ) {
      // let PreviewTableFields = BatchLevelData?.data?.preview_data?.preview_data
      //   ? JSON.parse(BatchLevelData.data.preview_data.preview_data)
      //   : {};
      // setSKU_Preview(PreviewTableFields);

      let PreviewTableFields = {};
      if (BatchLevelData?.data?.preview_data?.preview_data) {
        try {
          PreviewTableFields = JSON.parse(
            BatchLevelData.data.preview_data.preview_data
          );
        } catch (error) {
          toast.error("Failed to parse PreviewTableFields JSON");
        }
      }
      setSKU_Preview(PreviewTableFields);

      setLocation_id(
        BatchLevelData && BatchLevelData?.data?.preview_data?.location_id
      );
      setMain_cuts(
        BatchLevelData && BatchLevelData?.data?.preview_data?.number_of_cut
      );

      // const batchData =
      //   JSON.parse(BatchLevelData?.data?.preview_data?.size) || [];

      let batchData = [];
      if (BatchLevelData?.data?.preview_data?.size) {
        try {
          batchData = JSON.parse(BatchLevelData.data.preview_data.size);
        } catch (error) {
          toast.error("Failed to parse size JSON for batchData");
          batchData = []; // Set to an empty array if parsing fails
        }
      }

      const sizeOptions =
        BatchLevelData && BatchLevelData?.data?.size
          ? convertDropdown(BatchLevelData?.data?.size)
          : [];
      // setSize(sizeOptions);

      const sizeUpdatedCategories = sizeOptions?.map((sizeItem) => {
        // Find the matching item in batchData based on the 'id'
        const matchingBatchItem = batchData.find(
          (batchItem) => batchItem.id === sizeItem.id
        );

        // Return the updated object, adding the 'ratio' key
        return {
          ...sizeItem, // Keep existing properties
          ratio: matchingBatchItem ? matchingBatchItem.ratio : 0, // Add 'ratio' key
        };
      });

      setSize(sizeUpdatedCategories);

      // const colorData =
      //   JSON.parse(BatchLevelData?.data?.preview_data?.color) || [];

      let colorData = [];
      if (BatchLevelData?.data?.preview_data?.color) {
        try {
          colorData = JSON.parse(BatchLevelData.data.preview_data.color);
        } catch (error) {
          toast.error("Failed to parse color JSON for colorData");
          colorData = []; // Set to an empty array if parsing fails
        }
      }

      const colorOptions =
        BatchLevelData && BatchLevelData?.data?.color
          ? convertDropdown(BatchLevelData?.data?.color)
          : [];
      // setColor(colorOptions);

      const colorUpdatedCategories = colorOptions?.map((col) => {
        const matchingColorItems = colorData?.find(
          (colorItem) => colorItem.id === col.id
        );

        // Return the updated object, adding the 'ratio' key
        return {
          ...col, // Keep existing properties
          ratio: matchingColorItems ? matchingColorItems.ratio : 0, // Add 'ratio' key
        };
      });

      setColor(colorUpdatedCategories);

      if (colorUpdatedCategories) {
        const colorStored = colorUpdatedCategories.map(({ id, ratio }) => ({
          id,
          ratio,
        }));
        setColors(colorStored);
        validateForm();
      }

      // Check if sizeUpdatedCategories is defined
      if (sizeUpdatedCategories) {
        const batchStored = sizeUpdatedCategories.map(({ id, ratio }) => ({
          id,
          ratio,
        }));
        setCategories(batchStored);
        validateForm();
      }
    }
  }, [BatchLevelData]);

  // console.log("LOCATION: ", location_id, Main_cuts);
  // console.log("COLOR: ", color);

  // console.log("BATCHLEVEL DATA: ", BatchLevelData, categories);

  // console.log("COLORS: ", colors);
  // console.log("BATCH LEVEL DATA: ", categories);

  const getLocationValueById = (id, arr) => {
    const location = arr.find((item) => parseInt(item.id) === parseInt(id));
    return location ? location.value : null;
  };

  return (
    <div className="create-fit-wrapper">
      {BatchLevelData?.isLoading || filterData?.isLoading ? (
        <div className="loader_icons">
          <Loader />
        </div>
      ) : (
        <>
          <div className="d-flex align-items-baseline justify-content-between pb-2">
            <div className="heading heading-row-master-fit">
              <img
                onClick={() => navigate(-1)}
                src="/Images/arrow-left.svg"
                alt="Back_Icon"
              />
              <h2>Create a Manufacture Order</h2>
            </div>
            <div className="download-but-excel"></div>
          </div>

          <div className="select-box-wrapper-row">
            <div className="select-box-batch-level-grid-row">
              <div>
                <label htmlFor="" className="labelx">
                  Primary Location
                </label>
                <Select
                  className="mt-2"
                  placeholder="Primary Location"
                  options={location}
                  onChange={handleLocation}
                  value={getLocationValueById(location_id, location)}
                />
              </div>
              <div>
                <label htmlFor="" className="labelx">
                  Product Group
                </label>
                <input
                  className="mt-2 batch-input"
                  type="text"
                  value={product_group}
                  disabled
                />
              </div>
              <div>
                <label htmlFor="" className="labelx">
                  Number of Main Cuts
                </label>
                <input
                  className="mt-2 batch-input border-none"
                  type="text"
                  value={Main_cuts}
                  onChange={handleMainCutsChange}
                />
              </div>
            </div>

            <div className="color-size-grid-row">
              <div>
                <div className="size-arr-map">
                  {size.map((item) => (
                    <div key={item.id}>
                      <label htmlFor="" className="labelx">
                        {item.label}
                      </label>
                      <input
                        className="mt-2 batch-input border-none"
                        type="text"
                        placeholder={item.label}
                        defaultValue={item.ratio}
                        // value={item.ratio || ""}
                        onChange={(e) =>
                          handleInputChange(e.target.value, item.id)
                        }
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div>
                <div className="size-arr-map">
                  {color?.map((item) => (
                    <div key={item.id}>
                      <label htmlFor="" className="labelx">
                        {item.label}
                      </label>
                      <input
                        className="mt-2 batch-input border-none"
                        type="text"
                        defaultValue={item.ratio}
                        placeholder={item.label}
                        onChange={(e) =>
                          handleInputColor(e.target.value, item.id)
                        }
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="preview-sku-cta inc-wdth">
              {parseInt(form_type) === 2 && (
                <>
                  <Button onClick={handlePreviewBatchSKU}>
                    {isLoading ? <Loader /> : "Preview SKU’s Wise qty"}
                  </Button>
                  <Button onClick={handleCreateManufacturOrder}>
                    {isManufacture_Loading ? (
                      <Loader />
                    ) : (
                      "Create Manufacture order"
                    )}
                  </Button>
                </>
              )}
              {parseInt(form_type) === 0 && (
                <Button
                  // disabled={!isReadyToPreview}
                  onClick={handlePreviewBatchSKU}
                >
                  {isLoading ? <Loader /> : "Preview SKU’s Wise qty"}
                </Button>
              )}
              {parseInt(form_type) === 1 && (
                <Button
                  // disabled={!isReadyToPreview}
                  onClick={handleCreateManufacturOrder}
                >
                  {isManufacture_Loading ? (
                    <Loader />
                  ) : (
                    "Create Manufacture order"
                  )}
                </Button>
              )}
            </div>
          </div>

          <div className="table-row-component">
            <h2>Preview SKU’s</h2>
            <div className="table-container-pagination mt-3">
              {isLoading ? (
                <div className="loader_icons">
                  <Loader />
                </div>
              ) : (
                <DataTable header={Header} Batch_Preview={sku_preview} />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default BatchLevel;
